.IconContainer {
    cursor: pointer;
}

.IconColor {
    color: #2e2e2e;
}

.ItemContainer {
    min-width: 150px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding: 8px 14px 8px 6px;
    cursor: pointer;
}

.ItemContainer:hover {
    background-color: #FDEFCF;
}

.DisabledText{
    color: #a2a2a2 !important
}