.Container {
  border-radius: 0.75rem;
  border: 1px solid #80828a;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  display: inline-flex;
  height: 5rem;
  padding: 0rem 0.9375rem !important;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  overflow: hidden;
}
.Image {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  margin-right: 0.5rem !important;
}
.Title {
  color: #000 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.ASIN {
  color: #e6b540 !important;
  cursor: pointer;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
