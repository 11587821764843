
.Title{
    background-color: #697386 !important;
    text-align: center;
    color: white !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    min-width: 350px !important;
    display: flex;
    justify-content: space-between;
}

.BodyContainer{
    margin-top: 1.7rem;
    max-width: 100%;
}

.Body{
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
    color: #333333 !important;
    margin-bottom: 1.2rem !important;
}

.BoxConatiner{
    width: 355px;
    max-width: 100%;
    padding: 20px;
    border-radius: 14px;
}

.BoxTitle{
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: normal !important;
    margin-bottom: 10px !important;
    color: #2E2E2E !important;
}

.BoxDescription{
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: #80828A !important;
}

.ActionsContainer{
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 26px;
  width: 26px;
}