.DetailBoxContainer {
  padding: 1.56rem 1.88rem;
  background-color: white !important;
  border-radius: 0.25rem;
}

.CloseIcon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  color: #052460;
  cursor: pointer;
  margin-bottom: 1.5rem;
}
.Divider {
  margin: 1.88rem 0 !important;
  border-bottom: 0.0625rem solid #d9dbe3 !important;
}
.Title {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important; /* 150% */
  letter-spacing: -0.002rem !important;
  margin-bottom: 1.25rem !important;
}
.Body {
  color: #71747d !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  white-space: pre-line !important;
}
.Bullet {
  color: #71747d !important;
}
.RatingBreakDown {
  margin-right: 3.75rem;
}
.OverallRating {
  border-radius: 0.375rem;
  border: 1px solid #f0f2f5;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  padding: 1.69rem 0;
}
.OverallText {
  color: #000 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.OverallRatingText {
  color: #000 !important;
  font-size: 2.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 2.875rem !important; /* 115% */
  letter-spacing: -0.0075rem !important;
}
.ReviewContainer {
  border-radius: 0.375rem;
  border: 1px solid #f0f2f5;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  padding: 1.25rem 1.56rem 1.56rem 1.25rem;
  margin-bottom: 1.25rem !important;
}
.ReviewDate {
  color: #71747d !important;
  text-align: right !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.ReviewerImage {
  width: 3.125rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-radius: 3.125rem;
  margin-right: 1.25rem;
}
.ReviewerName {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
}
.ReviewBody {
  color: #71747d !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-left: 4.375rem !important;
  white-space: pre-line;
}
.ShowMoreReview {
  cursor: pointer !important;
  color: #e6b540 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
