.feedback-dialoge-box {
  min-width: 30rem;

  .dialog-title {
    background-color: #697386;
    color: white;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .feedbacks-container {
    overflow: auto;
    height: 250px;
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
  }

  .feedback {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    align-self: flex-start;
    max-width: 80%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.25rem;
  }

  .feedback-input {
    resize: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 3rem;
    padding: 0.75rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid gray;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.25rem;

    ::-webkit-input-placeholder {
      color: #5a5a5a;
    }

    ::-moz-placeholder {
      color: #5a5a5a;
    }

    :-ms-input-placeholder {
      color: #5a5a5a;
    }

    :-moz-placeholder {
      color: #5a5a5a;
    }
  }

  .MuiLinearProgress-root {
    background-color: lighten(#e6b540, 20);
  }

  .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: #e6b540;
  }
}

.FeedbackDialogBoxM {
  min-width: 10rem !important;
}
