.Container{
    padding: 1.5rem 2rem 0.5rem;
}
.BodyContainer{
    padding: 0px 32px;
}
.BodyContainerM{
    padding: 0px 20px;
}
.Heading {
    color: #080d1c !important;
    font-size: 1.5rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.875rem !important;
    letter-spacing: -0.003rem !important;
}