.Title {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00225rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.TitleM {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.RatingText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin: 0 1.25rem  !important;
}
.RatingTextM {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin: 0 0.31rem  !important;
}
.ReviewsText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  text-decoration: underline !important;
}
.ReviewsTextM{
  text-decoration: underline !important;
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}
.Price {
  color: #2e2e2e !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.875rem !important; /* 125% */
  letter-spacing: -0.003rem !important;
}
.PriceM{
  color: #2e2e2e !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.ActualPrice {
  color: #f00 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-right: 0.31rem !important;
  text-decoration: line-through;
}
.ActualPriceM{
  color: #f00 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin-right: 0.31rem !important;
  text-decoration: line-through;
}
.OtherImagesContainer {
  width: 3.125rem;
  height: 3.125rem;
  flex-shrink: 0;
  padding: 0.88rem 0.31rem;
  background-color: #fff !important;
  border: 1px solid #dee2e7 !important;
  margin-right: 0.31rem;
  border-radius: 0.25rem;
}
.MainImageContainer {
  border: 1px solid #e6b540 !important;
}
.LastImageContainer {
  background: rgba(0, 0, 0, 0.68) !important;
  position: relative;
}
.OtherImages {
  width: 2.5rem;
  height: 1.32269rem;
  flex-shrink: 0;
  object-fit: contain;
}
.LastImage {
  opacity: 0.1;
}
.ImageCount {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  leading-trim: both;
  text-edge: cap !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: bolder !important;
  line-height: normal !important;
}
.ImageContainer {
  position: relative;
  border: 1px solid #dee2e7;
  border-radius: 6px;
  padding: 1.5rem 1rem 0.94rem 0.94rem;
  margin-right: 1.25rem;
  /*width: 6.3125rem;*/
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ImageContainerM {
  position: relative;
  width: 4.8125rem;
  height: 4.4375rem;
  padding: 1.63rem 0.5rem 0.94rem 0.56rem;
  border: 1px solid #dee2e7;
  border-radius: 6px;
  margin-right: 0.64rem;
}
.Image {
  width: 4.375rem;
  flex-shrink: 0;
  object-fit: contain;
}
.ImageM {
  width: 3.75rem;
  height: 1.875rem;
  flex-shrink: 0;
  object-fit: contain;
}
.DetailImageContainer {
  position: relative;
  border: 1px solid #dee2e7;
  border-radius: 6px;
  padding: 2.44rem 1.31rem;
  margin-right: 1.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DetailsImage {
  width: 10.75rem;
  height: 5.6875rem;
  padding: 0;
  object-fit: contain;
  flex-shrink: 0 !important;
}
.Coupon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.375rem 0 0 0 !important;
  background: #fff3d8 !important;
  border: 1px solid #e6b540 !important;
  height: 1.125rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Discount {
  color: #2e2e2e !important;
  font-size: 0.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important;
}
