.ContainerClass {
    height: 56px !important;
    width: 100% !important;
    font-size: 14px !important;
}



.PhoneInputfield {
    box-shadow: none !important;
    height: 56px !important;
    width: 100% !important;
    border-radius: 10px !important;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    /* background-color: white !important; */
}

.PhoneInputfield:disabled {
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    background-color: white !important;
}

.PhoneInputfield:focus {
    border-color: #e6b540 !important;
}
.ContainerClass:focus{
    border-color: #e6b540 !important;
}
