.CaptionColor {
  color: #979797 !important;
  padding-left: 1rem;
}
.Tab {
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  height: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-top: 1rem;
}
.ButtonLogout {
  padding: 20px !important;
}
