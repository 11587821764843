.DropDownContainer {
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  background: #fff;
  padding: 1.3rem 0.31rem 1.25rem 0.81rem;
  cursor: pointer;
  margin-right: 0.81rem;
}

.DropDownOptions {
  padding: 1.3rem 0.31rem 1.25rem 0.81rem;
  cursor: pointer;
}

.DropDownContainerM {
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  background: #fff;
  padding: 0.86rem 0.37rem 0.86rem 0.62rem;
  margin-right: 0.81rem;
}
.DropDownOptionsM {
  padding: 0.65rem 0.37rem 0.65rem 0.62rem;
}
.Flag {
  width: 2.375rem;
  height: 1.35rem;
  fill: #fff;
  filter: drop-shadow(0px 5px 7px rgba(66, 71, 76, 0.08))
    drop-shadow(0px 0px 1px rgba(66, 71, 76, 0.32));
  margin-right: 0.88rem !important;
}
.FlagM{
  width: 2.375rem;
  height: 1.25rem;
  flex-shrink: 0;
  margin-right: 0.62rem !important;
}
.Name{
  color: #333;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;

  margin-right: 0.68rem !important;
}