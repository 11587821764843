.Container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.RangeText{
    font-size: 14px;
    font-weight: 500;
}

.PillInactive{
    font-size: 14px;
    padding: 6px 10px;
    background: #D9D9D9;
    border-radius: 25px;
    cursor: pointer;
}

.PillActive{
    font-size: 14px;
    color: white;
    padding: 8px 12px;
    background: #E6B540;
    border-radius: 25px;
    cursor: pointer;
}
