.Container {
  background-color: #f5f5f5;
  height: 100%;
  /*marginBottom: isMobile ? "130px" : "0px",*/
  margin-top: 5%;
}
.ContainerM {
  margin-bottom: 130px;
  max-width: 100vw !important;
}

.ButtonCaption {
  color: #80828a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 48px;
  margin-top: 8px;
}

.ButtonCaptionMobile {
  color: #80828a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 4px;
  width: 100%;
}