.CardContainer {
    height: 118px;
    width: 100%;
    flex-shrink: 0;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 #EFF0F6;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.CardContainerM {
    height: 100px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25);
    padding: 16px !important;

}


.CardTitle {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 21px !important;

}

.CardTitleM {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.Count {
    color: #000000 !important;
    text-align: center !important;
    width: 100%;
    font-size: 16px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    letter-spacing: -0.12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.CountM {
    color: #000000 !important;
    text-align: center !important;
    width: 100%;
    font-size: 16px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    letter-spacing: -0.12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.Subtitle{
    color: rgba(0, 0, 0, 0.70);
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 200% */
    margin: 0
}

.SubtitleM{
    color: rgba(0, 0, 0, 0.70);
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 200% */
    margin: 0
}