.Container {
  padding: 0 19.75rem;
}
.ContainerM {
  padding: 0 1.56rem;
  max-width: 100vw;
}
.Or {
  color: #393f46 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
  margin: 0 1.19rem;
}


