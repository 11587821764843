.Container {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  background: white;
}
.ContainerD {
  background: #fefae8;
}
.ContainerWidth {
  width: 640px;
}
.Checkmark {
  margin-right: 1rem;
  color: #2e2e2e;
}
.CheckmarkD {
  color: #b94b20;
}

.Title {
  color: #2e2e2e !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00225rem !important;
}
.TitleD {
  color: #b94b20 !important;
}
.Description {
  color: #80828a !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin: 0.5rem 0 1rem 0 !important;
}
.DescriptionD {
  color: #b94b20 !important;
}
.Actions {
  padding: 0.5rem 0 1rem 0 !important;
}

.ActionItem {
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 1.5rem;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;
}
.Back {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  margin-right: 0.5rem;
}
.BackD {
  border: 1px solid rgba(185, 75, 32, 0.2);
  background: rgba(185, 75, 32, 0.2);
}
.Reload {
  background: #e6b540;
}
.ReloadD {
  background: #b94b20;
}
.BackText {
  color: #80828a !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.ReloadText {
  color: #ffffff !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.ReloadTextD {
  color: #fefae8 !important;
}
