.BrandName {
    color: #e6b540 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
    max-width: 100% !important;
    overflow: hidden;
    font-family: Poppins !important;
}
.Email {
    color: #2E2E2E !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;    
    font-family: Poppins !important;

    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    width: 100% !important;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space: normal !important;
    /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.BrandAlias {
    color: #71747D !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
    /* line-height: 16px !important; */
}

.SwitchContainer {
    /* margin-top: 10px; */
    margin-bottom: 15px;
}

.HideBrandName {
    color: #80828A !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

.LastGeneratedContainer {
    border-top: 1px solid #F0F0F0 !important;
    padding-top: 10px !important;
    color: #71747D !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    margin-top: 15px !important;
}

.Title {
    color: #71747D !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}


.CellLinesLimitContainer {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    line-height: 28px !important;
    overflow: hidden !important;
    white-space: normal !important;
    /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}