.ProductEventsContainer {
    border-radius: 0.625rem !important;
    border: none !important;
    padding: 0 1rem !important;
    background-color: #fff;
    height: 100% !important;
    max-height: 100% !important;
    overflow-y: hidden !important;
}

.TitleBarWithFilter {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    margin-bottom: 4px;
    flex-direction: column;
    gap: 4px;
    position: sticky;
    top: 0; 
    background-color: white;
    z-index: 1; 
}

.ProdcutEventsTitle{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.ProdcutEventsDescription{
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    color: #80828a !important;
}

.Title{
    font-size: 12px !important;
    color: #000000 !important;
    line-height: 18px !important;
    font-weight: 600 !important;
} 

.SeeAll{
    font-size: 12px;
    color: #80828A;
    line-height: 16px;
    font-weight: 400;
    min-width: fit-content;
    font-family: "Poppins";
    cursor: pointer;
}

.EventsContaienr {
    height: 370px !important;
    min-height: 80%;
    overflow-y: auto;
}

.Filters{
    font-size: 14px !important;
}

.NoEventsAvailable{
    font-size: 14px !important;
    text-align: center !important;
    color: #80828A !important;
    padding-top: 26px;
    line-height: normal !important;
}
