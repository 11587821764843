.IconContainer {
    background: #ffffff;
    border-radius: 6px;
    padding: 6px;
    cursor: pointer;
}

.MenuItem {
    font-size: 14px !important;
    line-height: 110% !important;
    color: #979797 !important;
}

.IconColor {
    color: #646464;
}

.TextInput {
    width: 100%;
    max-width: 350px;
}

.ActiveColor {
    color: #2E2E2E !important;
    font-size: 14px !important;
}

.NonActiveColor{
    color: #80828A !important;
    font-size: 14px !important;
}

.ActiveLabel {
    font-size: 14px !important;
    color: #2E2E2E !important
}

.NonActiveLabel {
    font-size: 14px !important;
    color: #80828A !important
}