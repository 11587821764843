.CardContainer {
    height: 118px;
    width: 100%;
    flex-shrink: 0;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 #EFF0F6;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.CardContainerM {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 18px; */
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 6px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25);
    padding: 16px !important;

}

.CardTitle {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: -0.08px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.CardTitleM {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.08px !important;
}

.Count {
    color: #000000 !important;
    text-align: center !important;
    width: 100%;
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    letter-spacing: -0.12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.CountM {
    color: #000000 !important;
    text-align: center !important;
    width: 100%;
    font-size: 18px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    letter-spacing: -0.12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}