.Container{
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
}

.Heading{
    color: #828282 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.TH{
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding-right: 20px;
}

.DateColumn{
    padding-right: 30px !important;
}

.TD{
    color: #71747D;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100.5%;
    padding-top: 14px;
    padding-right: 20px;
}

.Table{
    margin-bottom: 16px;
}

.Footer{
    display: flex;align-items: center;
    gap: 10px;
    border-top: 1px solid #E4E7EC;
    padding: 10px 16px
}

.ListingBox{
    width: 9px;
    height: 9px;
    border: 2px solid #058527;
}
.TitleBox{
    width: 9px;
    height: 9px;
    border: 2px solid #89007E;
}

.Captions{
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}


.Tooltip{
    padding: 0 !important;
    border-radius: 4px !important;
    background: #FFF !important;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12) !important;
}

.TooltipArrow{
    color: #FFF !important;
}