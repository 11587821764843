.DialogTitle {
    text-align: center !important;
    background-color: #697386 !important;
    color: #FFFFFF !important;
}

.DialogFooter {
    border-top: 1px solid #D8D8D8 !important;
    margin-right: 4px !important;
}

.CancelButton {
    color: #979797 !important;
}

.SaveButton {
    margin-right: 12px;
}