
.ButtonCaption {
  color: #80828a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-right: 48px;
  margin-top: 8px;
}

.ButtonCaptionMobile {
  color: #80828a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 4px;
  width: 100%;
}
