.TabsContainer {
  padding-top: 1.25rem;
}

.TabHeader {
  color: #424242 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: none !important;
}

.TabHeaderActive {
  color: #e6b540 !important;
}

.Appbar {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
}
