.mui-data-grid {
  height: 90vh;
  width: 100%;
  padding: 3rem;

  & .MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
  }

  .MuiDataGrid-cell:focus {
    outline: transparent !important;
  }

  .MuiDataGrid-columnHeaderTitleContainer,
  .MuiDataGrid-columnHeaderTitleContainerContent,
  .MuiTypography-root {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    color: #2e2e2e;
  }
  .MuiDataGrid-root .MuiDataGrid-row {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    color: #71747d;
    border: none !important;
  }
  .not-selectable .MuiDataGrid-cell {
    color: gray !important;

    &.MuiDataGrid-cellCheckbox .MuiCheckbox-root {
      display: none;
    }
  }

  .green-row {
    background-color: (#79d2a6) !important;

    &:hover {
      background-color: (#79d2a6) !important;
    }
  }

  .light-green-row {
    background-color: lightgreen !important;
    &:hover {
      background-color: lightgreen !important;
    }
  }

  .not-selectable
    .MuiDataGrid-cell
    .MuiDataGrid-cellCheckbox
    .MuiCheckbox-root {
    color: gray;
  }
}
