// $drawer-width: 20rem;
.landing-page {
  display: flex;
  .lp-drawer {
    width: inherit;
    // z-index: 0;
    transition: 0.1s !important;
    transition-timing-function: ease-in-out !important;
    
    .MuiDrawer-paper {
      z-index: 0;
      border: none;
      width: inherit;
      box-sizing: border-box;
    }

    .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
      background-color: white;
    }
    .MuiList-root {
      .lp-drawer-list-item {
        height: 4rem;

        .icon {
          margin-right: 1rem !important;
        }
        .icon-active {
          margin-right: 1rem !important;
        }

        .item-text {
          .MuiTypography-root {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .active {
          color: #e6b540 !important;
        }
        .disable {
          color: #979797;
        }
      }
    }
  }

  .lp-tab-display-box {
    flex-grow: 1;
    padding: 0px;
    background: #f8f9fd;
    margin-top: 5rem;
    // height: 100vh;
    height: calc(100vh - 5rem);
    overflow: scroll;

  }
}