.Container {
}
.Heading {
  color: #000 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.SubHeading {
  color: #000 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin: 1.5rem 0 !important;
}
.ColorBox {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25);

}
.Selected{
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid #E6B540;
  box-shadow: 0 0 0 3px #e9decd inset;
}