.FetchProductCardM {
    background: #FFFFFF !important;
    border: none !important;
    width: 100vw !important;
}

.FetchProductCardHeaderM {
    background: #FFFFFF !important;
    border: none !important;
}

.FetchProductDonutTextM {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    line-height: 27px !important;
    font-weight: 600 !important;
    transform: translate(-50%, -50%) !important;
}

.FetchProductCardBodyTitle {
    font-size: 24px !important;
    line-height: 30px !important;
}

.FetchProductCardBodyDescription {
    font-size: 14px !important;
    line-height: 21px !important;
}
.OptionalTag{
    color: #E6B540 !important;
}