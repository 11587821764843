.Container {
  padding: 1.5rem 2rem 0.5rem;
}

.PaginationContainer {
  padding: 0rem 2rem 0.5rem;
}

.Heading {
    color: #080d1c !important;
    font-size: 1.5rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.875rem !important;
    letter-spacing: -0.003rem !important;
  }