.Container {
  height: 5rem;
  background-color: white;
  padding: 0.5rem;
}

.MContainer {
  height: 54px;
  background-color: white;
  padding: 0.5rem;
}
.Left {
  padding-left: 2rem;
}
.Right {
  padding-right: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.RightM {
  padding-right: 0rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.LimitContainer{
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: #FFF9EC;
  padding: 10px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.LimitContainerM{
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: #FFF9EC;
  padding: 4px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AsinText{
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #80828A;
}
