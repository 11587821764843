.TitleContainer {}

.Title {
    background-color: #697386 !important;
    text-align: center;
    color: white !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    min-width: 350px !important;
    display: flex;
    justify-content: space-between;
}

.BodyContainer {
    margin-top: 1.7rem;
    /* max-width: 100%; */
}

.Body {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
    color: #333333 !important;
    margin-bottom: 1.2rem !important;
}

.BoxConatiner {
    width: 350px;
    max-width: 100%;
    padding: 20px;
    border-radius: 14px;
}

.YellowBorder {
    border: 1px solid #E6B540;
}

.GrayBorder {
    border: 1px solid #D9D9D9;
}

.GrayBorder:hover {
    border-color: #E6B540 !important;
}

.BoxTitle {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: normal !important;
    margin-bottom: 10px !important;
    color: #2E2E2E !important;
}

.BoxDescription {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: #80828A !important;
}

.SampleLayoutContainer {
    margin-top: 15px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding: 10px;
}

.BottomAsinsFontSize {
    font-size: 7px !important;
}

.BoxRowTitle {
    color: #000 !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

.ExportOptionsTitle {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding-bottom: 15px !important;
}

.Description {
    width: 100% !important;
    max-width: 720px !important;
    color: #80828A !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding-bottom: 30px !important;
}