.TagContainer {
  display: inline-flex;
  height: 2.25rem;
  padding: 0.5rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerM {
  display: inline-flex;
  height: 1.5rem;
  padding: 0.5rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerS {
  display: inline-flex;
  height: 1.125rem;
  padding: 0.5rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerSM {
  display: inline-flex;
  height: 0.9375rem;
  padding: 0.5rem 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
/*Product Live Status*/
.Active {
  background: #0cc683 !important;
}
.Inactive {
  background: #ff4c05 !important; 
}
.Pause {
  background: #F0B59F !important;
}
.Pending {
  background: #898989 !important;
}
.StatusText {
  color: #ffffff !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;

}
.StatusTextM {
  color: #ffffff !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}
.StatusTextS {
  color: #ffffff !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;

}
.StatusTextSM {
  color: #ffffff !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important;

}
.InfoIconLight {
  color: #ffffff;
}
.InfoIconDark {
  color: #000000e0;
}
.LightStatusColor {
  color: #ffffff !important;
}
.SmallIcon{
  padding: 0.15rem 0;
}