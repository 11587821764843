.Container{
    border-radius: 4px;
    background: #4B9905;
    color: #FFF;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.028px;
    padding: 2px;
}

.Twenty{
    background: #980201;
}

.Forty{
    background: #994B01;

}

.Sixty{
    background: #9A9900;
}

.Eighty{
    background: #4B9905;
}

.Hundred{
    background: #008001;
}
