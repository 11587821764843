.Title {
    color: #000 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-top: 20px !important;
}

.Description {
    color: #80828A !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-top: 15px !important;
}

.ButtonContainer {
    margin-top: 40px;
    width: 310px;
}