
.TextInput{
    width: 450px !important;
}

.ToolBarContainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 32px;
    margin-top: 1.31rem;
}

.ToolBarContainerM {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    gap: 2rem;
}