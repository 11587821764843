.Container{
    padding: 25px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 15px;
    border: 0.5px solid rgba(245, 239, 235, 0.16);
    background: linear-gradient(135deg, #E6B540 -110.76%, rgba(230, 181, 64, 0.00) 116.5%);
}

.ContainerCollapsed{
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 15px;
    border: 0.5px solid rgba(245, 239, 235, 0.16);
    background: linear-gradient(135deg, #E6B540 -110.76%, rgba(230, 181, 64, 0.00) 116.5%);
}

.CreatingOrAdding{
    color: rgba(36, 34, 32, 0.40) !important;
    text-align: center;
    padding: 0 16px;
    margin-top: 6px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
}

.CreatingOrAddingClose{
    display: none;
}

.SmallButton{
    font-size: 10px !important;
    font-weight: 500;
    margin-top: 10px !important;
}

.SmallHeading{
    color: #242220;
    font-weight: 500 !important;
    text-align: center;

}

@container  (max-width: 70px){
    .CreatingOrAdding{
        display: none;
    }

    .Container{
        padding: 12px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 15px;
        border: 0.5px solid rgba(245, 239, 235, 0.16);
        background: linear-gradient(135deg, #E6B540 -110.76%, rgba(230, 181, 64, 0.00) 116.5%);

    }
}