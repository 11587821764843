.Star {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  object-fit: contain;
}
.Container {
  gap: 0.38rem;
}
.NoGap {
  gap: 0 !important;
}
