.ImageDiv {
    margin: 1.19rem 0.81rem 1.44rem 1.12rem;
}

.Image {
    width: 3.875rem;
    height: 3.625rem;
    flex-shrink: 0;
    object-fit: contain;
}

.DetailGrid {
    margin: 1.19rem 1.06rem 1.44rem 0;
}

.DetailGridM {
    margin: 0.75rem 1rem 0.75rem 0;
    word-break: break-word;
}

.Asin {
    font-feature-settings: "clig" off, "liga" off;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.00175rem !important;
    margin-bottom: 0.5rem;
    color: #000 !important;
    cursor: pointer;
}

.Asin:hover {
    text-decoration: underline !important;
    color: #e6b540 !important;
}

.Title {
    font-feature-settings: "clig" off, "liga" off;
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    word-break: break-word !important;
}
