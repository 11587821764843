.Container{
    padding: 25px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    margin-bottom: 10px;
}

.Hr{
    color:#F0F0F0
}