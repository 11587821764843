.ButtonsContainer {
    border-top: 1px solid #f4f4f4
}

.Headers {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.MenuItem {
    height: 24px !important;
    background-color: inherit !important
}

.ActiveColor {
    color: #2E2E2E !important
}

.NonActiveColor {
    color: #80828A !important
}

.CheckboxLabel {
    /* border: 1px solid red; */
    width: 265px;
    padding-right: 8px;
    line-height: 20px !important;
    height: fit-content;
}

.CheckboxLabelM{
    width: 100%;
    line-height: 20px !important;
    padding-right: 8px;
    height: fit-content;
}

.Slider{
    width: 82% !important;
    font-size: 10px !important;
}