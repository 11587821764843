.Dot{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #0a53be;
}

.Text{
    font-size: 12px;
    font-weight: 500;
}