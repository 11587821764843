.TooltipContainer {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: white !important;
}
.TooltipContent {
  color: #000000;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.CustomTooltipArrow {
  color: white;
}

.CustomTooltipTooltip {
  background-color: white !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 0.75rem;
  color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
