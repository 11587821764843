.H8{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.002em;
    color: #000000;
}


.SelectedStyledColumn {
    width: 100%;
    flex: 1;
    min-height: 200px;
    max-height: 200px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 14px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    overflow-y: scroll ;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE/Edge */
}

.SelectedStyledColumnRest {
    width: 100%;
    flex: 1;
    min-height: 900px;
    max-height: 900px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 14px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    overflow-y: scroll ;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE/Edge */
}

.RestStyledColumn {
    width: 100%;
    flex: 1;
    min-height: 690px;
    max-height: 690px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 14px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    overflow-y: scroll ;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE/Edge */
}


.SelectedCol{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.RestCol{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 690px;
}

.SelectedStyledColumn::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
}

.StyledColumn {
    width: 100%;
    flex: 1;
    min-height: 740px;
}

.StyledColumn h2 {
    margin: 0;
    padding: 0 16px;
}

.EmptySlot{
    display: flex;
    align-items: center;
    margin: 10px 0px;
    height: 48px;
    background: #FFFFFF;
    border: 1px dashed #D9D9D9;
    border-radius: 6px;
    padding: 10px
}

.Text{
    color: #71747D !important;
}


.Container{
    width: 100%;
    position: relative;
}


.ContainerMobile{
}


.Number{
    position: absolute;
    z-index: 500;
    color: #becde3;
    font-weight: bold;
    background: #f5f5f5;
}