.BottomNavigationLabelMB {
  color: #979797;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-size: 12px !important;
  padding-top: 4px !important;
}

.BottomNavigationLabelActiveMB {
  color: #e6b540;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-size: 12px !important;
  padding-top: 4px !important;
}

.LandingPageContentMB {
  /*margin-top: 12px;*/
  flex-grow: 1;
  /*padding: 12px;*/
  background: #f5f5f5;
  /*margin-bottom: 125px;*/
}

.ActiveTab {
  color: #e6b540 !important;
}

.DisableTab {
  color: #979797 !important;
}

.DrawerOpenCloseIcon {
  cursor: pointer;
  position: absolute;
  width: 100%;
  bottom: 32%;
}
.Left {
  padding-left: 2.5rem;
}