.Title {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
}

.Description {
    padding-top: 15px !important;
    color: #71747D !important;
    line-height: normal !important;
}

.ListContainer {
    padding: 30px 0px;
}

.TitleName {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: #2E2E2E !important;
}

.TitleNameM {
    font-size: 14px !important;
}

.ListItemContainer {
    border-bottom: 1px solid #f2f2f2;
    box-shadow: none;
    width: 100%;
    padding: 0px 30px;
    height: 116px;
}

.ListItemContainerM {
    border-bottom: 1px solid #f2f2f2;
    box-shadow: none;
    width: 100%;
    padding: 0px 20px;
    height: fit-content;
}