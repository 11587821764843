.BackButton {
    color: #80828a !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
.BackIcon{
    width: 20px;
}

.HeadingContainer{
    padding-right: 20px !important;
}