.TextInput {
    width: 450px !important;
}

.TextInputMobile {
    width: 100% !important;
}

.ToolBarContainer {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 1.31rem;
}

.ToolBarContainerM {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    gap: 2rem;
}

.ExportIconContainerM{
    background: #fff;
    border-radius: 0.25rem;
    height: fit-content;
    padding: 0.25rem;
}