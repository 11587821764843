.PrimaryColor {
    color: #E6B540 !important;
}

.UserName {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: -0.032px !important;
}

.UserDetails {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.UserAttributes {
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

.CaptionColor {
    color: #71747D !important;
}

.SubtitleColor {
    color: #80828A !important;
}