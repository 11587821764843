.Caption{
    color:#7E7E7E !important;

    text-align: center !important;
    line-height: normal !important;
    margin-top: 15px !important;
    margin-bottom: 34px !important;

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.CaptionM{
    text-align: center !important;
    line-height: normal !important;
    margin-top: 10px !important;
    margin-bottom: 45px !important;

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.TitlebarM{
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.ForgotButton{
    margin-top: 40px!important;
}

.OutlineInputMB {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 48px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.OutlineInputD {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 70px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}
