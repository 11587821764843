.AsinFormInputMB {
  border-radius: 10px !important;
  font-size: 14px !important;
  color: rgba(90, 90, 90, 1) !important;
  padding: 10px 10px 10px 16px !important;
  height: 48px !important;
}

.AsinFormInput {
  border-radius: 10px !important;
  /*width: 400px !important;*/
  font-size: 14px !important;
  color: rgba(90, 90, 90, 1) !important;
  padding: 10px 10px 10px 16px !important;
  height: 70px !important;
}

.AsinFormDescription {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}

.AsinFormButton {
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  line-height: 27px !important;
  width: 400px !important;
}

.AsinFormButtonMB {
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  line-height: 27px !important;
  width: 325px !important;
}

.fwBold600 {
  font-weight: 600 !important;
}
.WhatIsAsinContainer {
  border-radius: 2.0625rem;
  background: #e6e6e6;
  padding: 0.1875rem 0.625rem;
  gap: 0.625rem;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}
.WhatIsAsinText {
  color: #000 !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.HelpContainer {
  padding: 0.5rem 0.625rem;
  gap: 0.625rem;
  border-radius: 2.4375rem;
  background: #393f46;
}
.HelpText {
  color: #fff !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.NextArrow {
  cursor: pointer;
}
.WhatIsAsinContainer:hover {
  transform: scale(1.2);
}

.WhatIsAsinContainer:active {
  animation: arrowClick 0.2s ease-in-out;
}

@keyframes arrowClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.LimitText{
  margin-top: 18px;
  color: #80828A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.GoGoButton{
  width: 190px !important;
}

.ActionBoxText{
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px;
  color: #000;
}

.RunManual{
  width: 150px !important;
}