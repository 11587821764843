.Container {
    padding: 0;
}
.ContainerM {
    padding: 0.6rem 0rem;
}

.Title{
    font-size: 12px !important;
    color: #80828A !important
}

.StatusCircle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    height: auto !important;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%; /* Makes it a circle */
    font-size: 12px; /* Adjust font size as needed */
    margin-left: 5px !important;
    font-weight: 600 !important;
    padding: 2px;
}

.LiveCircle{
    background-color: #0CC683 !important;
    color: #ffffff !important
}
.PartiallyLiveCircle{
    background-color: #AAE2FA !important;
    color: #2E2E2E !important
}
.InactiveCircle{
    background-color: #FF4C05 !important;
    color: #FFFFFF !important
}
  