 .ProductCard {
     border: 1px solid white !important;
     padding: 16px !important;
     border-radius: 12px !important;
     position: relative;
 }

 .Checkbox {
     position: absolute;
     top: -7%;
     left: 0;
 }

 .Email {
     color: #e6b540 !important;
     line-height: 18px !important;
     font-weight: 600 !important;
     font-size: 18px !important;
     line-height: 22px !important;
 }

 .ProductDescriptionMB {
     color: #646464 !important;
     line-height: 21px !important;
     word-break: break-word;
 }

 .productEmailMB {
     color: #71747d !important;
     line-height: 18px !important;
     font-size: 12px !important;
     font-weight: 500 !important;
 }

 .productDateMB {
     padding-top: 0.5rem !important;
     color: #71747d !important;
     line-height: 16px !important;
     font-size: 10px !important;
     font-weight: 400 !important;
 }

 .DeleteButton {
     pointer-events: all;
     cursor: pointer;
 }

 .productImageMB {
     object-fit: contain;
     width: 99px;
     height: 122px;
     padding: 6px;
 }

 .IconSkeleton {
     height: 20px !important;
     width: 20px !important;
     border-radius: 2px !important;
 }

.UserDetails {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.UserAttributes {
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}
.SubtitleColor {
    color: #80828A !important;
}