.Caption{
    color: #7E7E7E !important;
    text-align: center;
    text-decoration: underline;
}

.CaptionActive{
    color: black;
    text-align: center;
    text-decoration: underline;
}

.SelectTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7E7E7E !important;
    cursor: pointer;
}

.SelectTextContainerActive{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black !important;
    cursor: pointer;
}


.DoAndDontText{
    color: #71747D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
}

.DoText{
    color: #0CC683;
    margin-left: 6px;
}

.DontText{
    color: #F00;
    margin-left: 6px;
}

.SearchBarDesktop {
    width: 290px !important;
}

.SearchBarMobile {
    width: 100% !important;
}