.AvailableColor{
    color: #0CC683;
    font-size: 12px;
}

.NotAvailableColor{
    color: #FF4C05;
    font-size: 12px;
}

.MenuItem{
    color: #80828A;
    font-size: 14px;
}
.ErrorText {
    color: #FF3333 !important;
    font-size: 0.75rem !important;
    line-height: 1.66 !important;
    margin-top: 3px !important;
    margin-right: 14px !important;
    margin-bottom: 0 !important;
    margin-left: 14px !important;
}