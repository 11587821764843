.Tabs{
        border-bottom: 1px solid #D8D8D8 !important;

}

.Tab{
        text-transform: none !important;
        font-weight: 600 !important;
        color: #80828A !important;
}

.Selected{
        color: #e6b540 !important;
}

.Disabled{

        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: normal !important;
        letter-spacing: normal !important;
}