.KpiCard {
    height: 140px;
    flex-shrink: 0;
    border-radius: 6px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25);
    padding: 12px !important;
}

.KpiCardM {
    height: 104px;
    flex-shrink: 0;
    border-radius: 6px !important;
    background: #FFF !important;
    box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25);
    padding: 8px !important;
}

.KpiCardTopDiv {
    border-radius: 4px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.KpiCardTopBottomText {
    font-size: 12px !important;
    font-style: normal !important;
    text-align: center;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: -0.08px !important;
    border-radius: 4px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.KpiCardTopBottomTextM {
    text-align: center;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.08px !important;
    border-radius: 4px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.KpiCardMiddleText {
    color: #2E2E2E !important;
    text-align: center !important;
    letter-spacing: -0.12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 20px!important;
}

.KpiCardMiddleTextM {
    color: #2E2E2E !important;
    font-size: 20px !important;
    text-align: center !important;
    font-weight: 600 !important;
    letter-spacing: -0.04px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}