.ContainerClass {
    background-color: white !important;
    /* color: rgb(104, 7, 60); */
    height: 70px !important;
    width: 100% !important;
    font-size: 14px !important;
}



.PhoneInputfield {
    box-shadow: none !important;
    height: 70px !important;
    width: 100% !important;
    border-radius: 10px !important;
    border: 1px solid #d9d9d9;
    font-size: 14px;
}

.PhoneInputfield:focus {
    border-color: #e6b540 !important;
}
.ContainerClass:focus{
    border-color: #e6b540 !important;
}

@media screen and (max-width: 1024px) {
    .PhoneInputfield {
        height: 48px !important; /* Adjust width as needed */
    }
    .ContainerClass {
        height: 48px !important;
    }
}