.Text{
    color: #181818;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Container{
    display: flex;
    border-radius: 8px;
    background: #EAF3FF;
    align-items: center;
    padding: 14px 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.ContainerMobile{
    display: flex;
    /* flex-direction: column; */
    border-radius: 8px;
    background: #EAF3FF;
    padding: 14px 8px;
    margin: 0px 8px;
    margin-bottom: 8px;
}

.Button{
    width: 200px !important;
}