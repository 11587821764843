.ButtonsContainer {
    border-top: 1px solid #f4f4f4
}
.Headers {
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 300px !important;
}
.MenuItem{
    height: 24px !important;
    background-color: inherit !important
}

.ActiveLabel {
    font-size: 14px !important;
    color: #2E2E2E !important;
    line-height: 15px !important;
}

.NonActiveLabel {
    font-size: 14px !important;
    color: #80828A !important;
    line-height: 15px !important;
}