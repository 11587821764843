.BsrCard {
    border-radius: 0.625rem !important;
    border: none !important;
    padding: 1rem !important;
}
.ContentHeading {
    color: #000 !important;
    font-size: 14px !important;
    font-feature-settings: "clig" off, "liga" off;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
}
.BsrMenuItem {
    font-size: 12px !important;
    color: #333 !important;
    line-height: normal !important;
    font-style: normal !important;
    font-weight: 500 !important;
    letter-spacing: -0.08px !important;
}

.BsrMenuItemM {
    font-size: 8px !important;
    color: #333 !important;
    line-height: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    letter-spacing: -0.08px !important;
}

.BRSNoData{
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 80px;
    gap: 20px;
}

.NODataText{
    text-align: center;
    color: #D0D0D0 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

.NORatingText{
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #bfbfbf !important;
    padding-left: 4px !important;
}

.BsrDateBox{
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding: 8px;
}
.BsrDateText{
    font-size: 12px !important;
    font-weight: 600 !important;
    color:#333333 !important;
    line-height: 18px !important;
}

.ChartTitleText{

    color: #E6B540;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    display: flex;
    margin-bottom: 10px ;
    align-items: center;
}

.TopMargin{
    margin-top: 35px;
}

.DataAccuracyText{
    color: var(--Gray, #80828A);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    gap: 2px;
    align-items: center;
}