.TagPopperContainer {
  border-radius: 0.25rem;
  border: 1px solid #fff;
  background: #fff;
}
.CreateTag {
  color: #e6b540 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.CreateTag:hover {
  background-color: #ffe4ba;
}
.TagOption {
  color: #80828a !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.CircleContainer {
  /*margin-left: 0.62rem;*/
  /*margin-right: 0.81rem;*/
}
.Circle {
  width: 0.5rem !important;
  height: 0.5rem !important;
  margin: 0 1rem 0 0.5rem;
}
.Options {
  color: #80828a;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.OptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.MoreTagOptions {
  padding: 0.5rem 1rem;
}
.MoreTagOptions:hover {
  background-color: #eaeaea;
}
