.MuiDataGridRoot {
  border: none !important;
  height: 50rem !important;
}

.CellWithRenderer {
  border-bottom: 1px solid #f2f2f2 !important;
}

.FootContainer {
  border-top: 1px solid #f2f2f2 !important;
}

.ColumnHeaders {
  border-bottom: 1px solid #f2f2f2 !important;
}

.ColumnSeparator {
  display: none !important;
}