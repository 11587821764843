.HeadingText{
    font-weight: 600;
    letter-spacing: -0.028px;
    margin-top: 6px
}

.Container{
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    padding: 12px 10px;
    cursor: pointer;
}
.ActiveContainer{
    border-radius: 14px;
    border: 1px solid #E6B540;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    padding: 12px 10px;
    cursor: pointer;
}

.CaptionText{
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.Green {
    color: #058527 !important;
}
.LightGreen {
    color: #8da45df2;
}
.Grey {
    color: #71747d;
}

.EmptyIcon{
    width: 20px;

}