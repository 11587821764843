.Flag {
  width: 1.3125rem;
  height: 0.8125rem;
  fill: #fff;
  object-fit: contain;
  filter: drop-shadow(0px 5px 7px rgba(66, 71, 76, 0.08)) drop-shadow(0px 0px 1px rgba(66, 71, 76, 0.32));
  margin-right: 0.31rem;
}
.FlagName {
  color: #71747d !important;
  font-size: 0.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important; /* 200% */
}

.ProductCard {
  /*background: #F5F5F5 !important;*/
  border: 1px solid white !important;
  /*filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08)) !important;*/
  padding: 16px 8px 2px 8px !important;
  border-radius: 0 !important;
  position: relative;
}
.Checkbox{
  position: absolute;
  top: -7%;
  left: 0;
}

.ProductAsinMB {
  color: #e6b540 !important;
  line-height: 24px !important;
}

.ProductDescriptionMB {
  color: #646464 !important;
  line-height: 21px !important;
  word-break: break-word;
}

.productEmailMB {
  color: #71747d !important;
  line-height: 18px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.productDateMB {
  padding-top: 0.5rem !important;
  color: #71747d !important;
  line-height: 16px !important;
  font-size: 10px !important;
  font-weight: 400 !important;
}

.productImageMBMain {
  object-fit: contain;
  width: 99px !important;
  height: 122px !important;
  border-radius: 4px !important;
  background-color: #f5f5f5 !important;
}

.DeleteButton {
  pointer-events: all;
  cursor: pointer;
}

.productImageMB {
  object-fit: contain;
  width: 99px;
  height: 122px;
  padding: 6px;
}
