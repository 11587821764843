.ProductMainInfoCard {
  border: none !important;
  background: none !important;
}

.ProductImage {
  object-fit: contain;
  width: 132px !important;
  height: 132px !important;
  border: 1px solid #dee2e7 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  padding: 8px !important;
}

.ProductAsin {
  line-height: normal !important;
  letter-spacing: -0.04px !important;
  margin-right: 1rem !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.ProductTitle {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: rgba(0, 0, 0, 0.8) !important;
  margin: 0.63rem 1rem 0.63rem 0 !important;
}

.ProductBrand {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.ProductBrandM {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.08px !important;
}

.FlagIcon {
  margin-left: -5px !important;
  width: 2.42306rem;
  height: 1.5rem;
  filter: drop-shadow(0px 5px 7px rgba(66, 71, 76, 0.08)) drop-shadow(0px 0px 1px rgba(66, 71, 76, 0.32)) !important;
}

.FlagIconM {
  margin-left: -5px !important;
  width: 32px !important;
  height: 20px !important;
  filter: drop-shadow(0px 5px 7px rgba(66, 71, 76, 0.08)) drop-shadow(0px 0px 1px rgba(66, 71, 76, 0.32)) !important;
}

.FlagText {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.08px !important;
  vertical-align: super !important;
}

.FlagTextM {
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100.5% !important;
  vertical-align: super !important;
}

.ContentHeading {
  color: #000 !important;
  font-size: 14px !important;
  font-feature-settings: "clig" off, "liga" off;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.028px !important;
}

.SelectedKeywords {
  color: #80828a !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100.5% !important;
  margin-left: 1rem !important;
}

.Container {
  padding: 1.87rem 4.38rem 0;
}

.SearchVolumeExposureRowM {
  --bs-gutter-x: 0.9rem !important;
  row-gap: 0.5rem;
}

.RowColPadding>* {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.KeywordCompetitorCard {
  display: flex;
  justify-content: center;
  height: 150px;
  padding: 15px 26px 21px 27px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: rgba(230, 181, 64, 0.18);
}

.KeywordCompetitorCardTitle {
  font-size: 20px !important;
  line-height: normal !important;
  letter-spacing: -0.04px !important;
  color: #141414 !important;
}

.KeywordCompetitorCardSubTitle {
  color: #141414 !important;
  text-align: center !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.BottomSheetTitle {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.028px !important;
  padding-left: 1rem !important;
}

.BottomSheetSubTitle {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
  margin: 1rem !important;
}

.KeywordsHeading {
  color: #000 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}

.ChagneKeywords {
  cursor: pointer;
  color: #E6B540 !important;
  font-size: 14px !important;
  font-feature-settings: "clig" off, "liga" off;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.028px !important;
}

.Blurred {
  color: transparent !important;
  word-break: break-all;
  text-shadow: 0 0 8px #6f6962;
  pointer-events: none;
  user-select: none;
}

.OverAvgCompText {
  color: #80828A;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center
}


.Title {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00225rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.TitleM {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.RatingText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin: 0 1.25rem  !important;
}
.RatingTextM {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin: 0 0.31rem  !important;
}
.ReviewsText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  text-decoration: underline !important;
}
.ReviewsTextM{
  text-decoration: underline !important;
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}
.Price {
  color: #2e2e2e !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.875rem !important; /* 125% */
  letter-spacing: -0.003rem !important;
}
.PriceM{
  color: #2e2e2e !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.ActualPrice {
  color: #f00 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-right: 0.31rem !important;
  text-decoration: line-through;
}
.ActualPriceM{
  color: #f00 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin-right: 0.31rem !important;
  text-decoration: line-through;
}