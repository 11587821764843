.Title{
    background-color: #697386 !important;
    text-align: center;
    color: white !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    min-width: 350px !important;
    display: flex;
    justify-content: space-between;
}

.BodyContainer{
    width: 65vw;
    padding: 0px 50px 50px !important;
}

.Body{
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
    color: #333333 !important;
    margin-bottom: 1.2rem !important;
}

.BoxConatiner{
    width: 355px;
    max-width: 100%;
    padding: 20px;
    border-radius: 14px;
}