.Container{
  padding: 2rem;
}
.CellLinesLimitContainer {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.Flag {
  width: 1.3125rem;
  height: 0.8125rem;
  fill: #fff;
  object-fit: contain;
  filter: drop-shadow(0px 5px 7px rgba(66, 71, 76, 0.08)) drop-shadow(0px 0px 1px rgba(66, 71, 76, 0.32));
  margin-right: 0.31rem;
}
.FlagName {
  color: #71747d !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
  margin-left: 0.31rem !important;
}
.Image {
  width: 3.25rem !important;
  height: 3.25rem !important;
  object-fit: contain;
  margin-right: 0.62rem !important;
}
.ASIN {
  color: #e6b540 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.InputContainer {
  padding-left: 3rem;
  margin-bottom: 1rem;
  margin-top: 1.31rem;
}
.InputContainerMobile {
  margin-bottom: 1rem;
}

.TextInput {
  width: 450px !important;
}

.TextInputMobile {
  width: 100% !important;
}

.ToolbarsContainer {
  padding-right: 1.3rem;
}
.ToolbarOptions {
  color: #e6b540 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}
.ToolbarIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.Tag {
  color: #e6b540 !important;
  gap: 0.25rem;
}

.TagContainer {
  display: flex;
  height: 1.125rem;
  padding: 0.5rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  border-radius: 437.5rem;
}

.DisabledText {
  color: #a2a2a2 !important
}