.editIconAndTextColor {
  color: #e6b540 !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.editIconAndTextColorM {
  color: #e6b540 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text1Class {
  color: #898989 !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text1ClassM {
  color: #898989 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}

.text2Class {
  color: #5a5a5a !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text2ClassM {
  color: #5a5a5a !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}
