.ForgotPassword {
    color: #D93F21 !important;
    cursor: pointer;
}

.TextColor {
    color: white !important;
}

.PageTitleD {
    font-size: 40px !important;
    line-height: 46px !important;
}

.PageTitleMB {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    margin-top: 40px !important;
}

.PageCaptionM{
    margin-top: 10px !important;
    margin-bottom: 36px !important;
}
.OutlineInputMB {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 48px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.OutlineInputD {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 70px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.TextOverImage {
    font-size: 35px !important;
    line-height: 47px !important;
    font-weight: 600 !important;
}
.TitlebarM{
    margin-top: 30px !important;
}

.PaddingX9{
    padding-left: 9rem;
    padding-right: 9rem;
}