.Container{
    margin-top: 30px !important;
}
.SectionHeading{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
}
.BulletHeading{
    color: #71747D !important;
    line-height: normal !important;
}

.BulletHeadingBold{
    font-weight: bold !important;
}

.ML-50{
    min-width: 40px;
}

.PreviewBulletCard{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    margin-top: 13px;
    padding:30px !important
}

.PreviewBulletCardM{
    border-radius: 10px;
    background: #F0F2F5;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    margin-top: 13px;
    padding:20px 10px !important
}
.PreviewBullet{
     color: #000;
     font-size: 14px !important;
     font-style: normal !important;
     font-weight: 400 !important;
     line-height: normal !important;
 }
.PreviewButtonM{
    background-color: white !important;
    border: 1px solid #E6B540;
    border-radius: 10px;
    width: 75%;
}