.keywords-container {
  .kw-relevancy {
    // .mui-data-grid .MuiTypography-root {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    // }
  }
  .green {
    color: #058527 !important;
  }
  .light-green {
    color: #8da45df2;
  }
  .gray {
    color: rgba(113, 116, 125, 0.40) !important;
    cursor: not-allowed;
  }
  .kw-h2 {
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
    line-height: 44px;
    color: #000000;
    margin-bottom: 1.2rem;
    text-align: center;
    padding: "30px";
  }

  .kw-text {
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.035px;
    line-height: 11px;
    text-align: center;
    color: #7e7e7e;
    margin-bottom: 1.2rem;
  }

  .kw-all-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 90%;
    color: #b94b20;
  }

  .kw-see {
    width: 10%;
    cursor: pointer;
    display: flex;
    font-weight: bolder;
    text-decoration: underline;
    justify-content: flex-end;
  }

  .kw-button {
    background-color: #e6b540;
    color: white;
    margin: 0.5rem;
    // width: 7rem;
    width: 25rem;
    height: 55px;
    padding: 1.15rem;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 0.7rem;

    &:hover {
      background-color: #e6b540;
    }

    &.Mui-disabled {
      color: white;
      background-color: rgba(222, 200, 148, 0.879);
      cursor: wait;
      pointer-events: all;

      &:hover {
        background-color: rgba(222, 200, 148, 0.879);
      }
    }
  }
}
