.Text {
    color: #2E2E2E;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.04px;
    margin-top: 16px;
    margin-bottom: 10px;
    text-align: center !important;
}

.LogoContainer {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 10px 0px #F0F2F5;
    background-color: #fff;
    border-radius: 10px;
}
.LogoContainerM {
    width: 200px !important;
    height: 200px !important;
}

.Authorizing {
    text-align: center;
    font-size: 16px !important;
    color: #80828a;
    font-family: 'Poppins';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 20px;
    
}