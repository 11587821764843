.TooltipCard {
    max-width: 270px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
}
.TooTipInfoTitle{
    font-weight: 400;
    font-size: 12px;
    /*line-height: 16px;*/
    color: #000000;
}
.TooTipInfoText{
    margin-left: 4px;
    font-weight: 500;
    font-size: 12px;
    /*line-height: 11px;*/
    color: #000000;
}

.Title{
    color: #ff0000;
    font-size: 12px;
    font-weight: 700;
    margin-left: 1.9rem;
    margin-top: -30px;
}

.HighTitle{
    color: #0CC683;
    font-size: 12px;
    font-weight: 700;
    margin-left: 1.9rem;
    margin-top: -30px;
}

.CustomDivider{
    margin: 0.5rem 0 !important;
}