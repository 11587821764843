.Container{
    padding: 2rem;
}

.MobileContainer{
    margin-bottom: 80px;
}

.CellLinesLimitContainer{
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space: normal !important;
    /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}
.Name{
    color: #e6b540 !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
.InputContainer{
    padding-left: 3rem;
    margin-bottom: 1rem;
    margin-top: 1.31rem;
}
.InputContainerMobile{
    margin-bottom: 1rem;
}
.TextInput{
    width: 450px !important;
}
.TextInputMobile{
    width: 100% !important;
}
.Tag{
    color: #e6b540 !important;
    gap: 0.25rem;
}
.TagContainer{
    display: flex;
    height: 1.125rem;
    padding: 0.5rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 437.5rem;
}
.DisabledText{
    color: #a2a2a2 !important
}
.ToolBarContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.31rem;
}

.ToolBarContainerM {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    gap: 2rem;
}