.EventCardContainer {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #F5F5F5;
}

.ActiveContainer {
    background-color: #FFF7E6 !important;
    
}

.EventTitle {
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    color: #2e2e2e !important;
    padding-bottom: 4px !important;
}

.EventDescription {
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    color: #80828a !important;
}

.EventDate {
    font-size: 10px !important;
    color: #80828A !important;
    line-height: 16px !important;
    font-weight: 400 !important;
}