.Container {
  border-radius: 14px;
  background-color: white;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.Container:hover {
  transform: scale(0.95);
}

.Container:active {
  transform: scale(0.85);
}

.ContainerM {
  border-radius: 14px;
  background-color: white;
  cursor: pointer;
}

.Image {
  width: 5.875rem;
  height: 5.5rem;
  flex-shrink: 0;
  object-fit: contain;
}

.TitleGrid {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1.5rem;
  margin-right: 1rem;
  height: 4.125rem;
}

.Title {
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.375rem !important;
}

.Percentage {
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
  margin-right: 0.5rem !important;
}

.Relevancy {
  /*margin: 1.38rem 1.69rem 0rem;*/
}

.SelectIcon {
  color: #e6b540;
  cursor: pointer;
}

.Rating {
  color: #898989 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}

.RatingGrid {
  height: 1.25rem;
  flex-shrink: 0;
}

.Price {
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
}
