.After {
  width: 50%;
  margin-right: 0.375rem;
  display: flex;
  flex-direction: column;
}
.TagAfter {
  background: #b94b20;
  border: 1px solid #b94b20;
}
.TagSuccess {
  background: #0CC683;
  border: 1px solid #0CC683;
}
.TagAfterColor {
  color: white;
}

.TagGrid {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 4px;
  margin-left: 1rem;
  width: fit-content;
  height: 16px !important;
}

.TagFont {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}
