.Container {
  padding: 0rem 2rem 2rem 2rem;
}

.Input {
  width: 450px !important;
}

.InActiveIcon {
  color: #80828a;
}

.ActiveIcon {
  color: white;
}
.MarginPagination{
  margin-bottom: 120px;
}
.LoadingContainer{
  position: absolute;
  top: 50%;
  left: 50%;
}