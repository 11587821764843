.TextContainer{
    min-width: fit-content;
}
.Text {
    color: #080D1C;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.SubtitleText{
    color: #080D1C;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Container{
    display: flex;
    border-radius: 8px;
    box-sizing: border-box;
    background: #EAF3FF;
    align-items: center;
    padding: 24px 20px;
    /* margin-bottom: 20px; */
    justify-content: space-between;
}

.ContainerMobile{
    display: flex;
    flex-direction: column;
    max-width: 100vw !important;
    border-radius: 8px;
    background: #EAF3FF;
    padding: 8px 20px;
    /* margin-bottom: 20px; */
}

.Button{
    width: 150px;
}