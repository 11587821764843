.AddButton{
    padding-top: 8px;
    padding-bottom: 4px
}


.KeywordText{
    font-weight: 600 !important;
    margin-top:10px !important;
}

.VerticalLine {
    position: relative;
    width: 1px;
    background-color: #D9DBE3;
}

.VerticalLine::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: #D9DBE3;
}

.VerticalLineIcon{
    position: absolute;
    top: 48%;
    right: 10px;
    width: 20px;
    height: 20px;
    left: -9px;
    background: #FEF;
}

.TitleKeywordContainer{
    display: flex;
    flex-direction: column;
}

.RestOfKeywordsContainer{
    display: flex;
    flex-direction: column;
}


.CaptionText{
    font-size: 12px !important;
}

.SellingColumn{
    max-height: 750px;
    min-height: 750px;
    overflow-y: scroll ;
    border-radius: 14px;
    margin-top: 6px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    padding-left: 8px;
    padding-right: 8px;
}

.AddToText{
    color: #80828A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    margin-bottom: 6px;
}

.MT3{
    margin-top: 21px;
}

.Divider {
    border: none;
    height: 1px;
    background-color: #D8D8D8;
    margin-top: -5px;
}

.SeoColumn{
    max-height: 800px;
    min-height: 800px;
    border-radius: 14px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    overflow-y: scroll ;
    padding-left: 8px;
    padding-right: 8px;
    /*scrollbar-width: none; !* Hide the scrollbar for Firefox *!*/
    /*-ms-overflow-style: none; !* Hide the scrollbar for IE/Edge *!*/
}
.SeoColumnMobile{
    border-radius: 14px;
    border: 2px dashed #becde3;
    background: #f5f5f5;
    max-height: auto;
    overflow-y: scroll ;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE/Edge */

}

.MobileContainer{
    border-radius: 6px;
    background: #FFF;
    padding: 10px;
}

.SeoColumn::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
}

.SellingColumn::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
}

.MobileContainer{
    /*width: 100%; overflow: auto; display: flex*/
    display: flex;
    flex-direction: column;
    gap:16px;
}

.DesktopContainer{
    padding: 0 80px
}

.TotalNumber{
    color: #80828A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}


.ClampButton{
    max-width: 100% !important;
    text-align: center;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}