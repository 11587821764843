.two-color-text {
  display: flex;
  justify-content: center;
  align-items: center;
  .font {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: black;
  }
  .color-text {
    color: #e6b540;
    cursor: pointer;
  }
}
