.Input  {
    width: 100%;
    min-width: 360px;
}

.Container{
    position: relative;
}

.Icon{
    position: absolute;
    right: 10px;
    top: 14px
}

.IconM{
    position: absolute;
    right: 8px;
    top: 10px
}