.Dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0a53be;
}

.Text{
    font-size: 12px;
    font-weight: 500;
}

.CheckBoxIconFontSize{
    font-size: 32px !important;
}