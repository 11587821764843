.CaptionColor {
    color: #7E7E7E !important;
    line-height: 21px !important;
    font-size: 14px !important;
}

.TermText {
    color: #e6b540 !important;
    cursor: pointer;
    text-decoration: none;
}

.CreateButton {
    margin-top: 10px!important;
}

.PageTitleD {
    font-size: 40px !important;
    line-height: 46px !important;
}

.PageTitleMB {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    margin-top: 40px !important;
}
.PageCaptionM{
    margin-top: 10px !important;
    margin-bottom: 36px !important;
}

.OutlineInputMB {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 48px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.OutlineInputD {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 70px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.TextOverImage {
    font-size: 35px !important;
    line-height: 47px !important;
    font-weight: 600 !important;
}
.TitlebarM{
    margin-top: 30px !important;
}

.PaddingX5{
    padding-left: 5rem;
    padding-right: 5rem;
}
.PaddingX9{
    padding-left: 9rem;
    padding-right: 9rem;
}

.CheckBoxContainer{
    display: flex;
    gap: 0px;
    align-items: center;
}

.CheckBoxText{
    color: #5A5A5A;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    font-feature-settings: 'clig' off, 'liga' off;

}

.TwoColorText{
    color: #E6B540;
    cursor: pointer;
}