.TitleContainer {
}
.Title {
  background-color: #697386 !important;
  text-align: center;
  color: white !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.BodyContainer {
  margin-top: 1.7rem;
}
.Body {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1.1rem !important;
  color: #333333 !important;
  margin-bottom: 1.2rem !important;
}
.Cancel {
  color: #80828a !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  line-height: 0.7rem !important;
  cursor: pointer !important;
  margin-right: 1rem !important;
}
