.BackButton {
  color: #80828a !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.HeaderContainer {
  background: #f8f9fd;
  position: sticky;
  top: 0px;
  z-index: 11
}