.TableContainer {
  padding-right: 1.13rem !important;
  padding-left: 1rem !important;
}

.TableHeader {
  color: #000 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
  margin-bottom: 0.25rem !important;
}

.TableHeaderCaption {
  font-size: 0.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important;
}

.TableCell {
  color: #71747d !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 0 !important;
}

.PhraseCell {
  color: #e6b540 !important;
}
.TitlePhraseCell {
  color: #5e5a9f !important;
}
.UnderlineCell {
  text-decoration: underline;
  cursor: pointer;
}

.BorderCell {
  border: 2px solid #058527;
  padding: 0.44rem 0.88rem 0.44rem 0.33rem !important;
}
