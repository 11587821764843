.Container{
  padding: 0px 2rem 2rem;
}
.PrimaryColor {
  color: #e6b540 !important;
}
.DeleteIcon {
  margin-left: 25px !important;
}

.CellLinesLimitContainer {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.Email {
  color: #71747d !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}
.HideButtonContainer{
  padding: 1.5rem 2rem 0.5rem;
}

.TextInput{
  width: 450px !important;
}
.TextInputMobile{
  width: 100% !important;
}