.CellLinesLimitContainer {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space: normal !important; /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.AccordianHeaderText{
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.AccordianHeader{
    background: #FFF3D9 !important;
}
.Root{
    border: 1px solid #E6B540 !important;
    border-radius: 2px !important;
    box-shadow: none !important;
}
.RootNotActive{
    border-radius: 2px !important;
    box-shadow: none !important;
    border: 1px solid #D9D9D9 !important;
}

.SeeMore{
    margin-top: -40px !important;
}

.CellText {
    color: #80828A;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16px; */
    line-height: normal;
}

.DisabledText{
    color: #a2a2a2 !important
}