.TableHeading{
    color: #2E2E2E;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    padding-bottom: 6px;
    background: #F8F9FD !important;
}

.TableCells{
    color: #71747D;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
}

.TableCellPrimary{
    color: #E6B540;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin: 0;
}

.TableContainer{
    height: 500px !important;
    overflow-y:  scroll !important;
}

.TableContainer::-webkit-scrollbar{
    width: 8px !important;
    height: 8px !important;
    border-radius: 2px !important;
}

.TableContainer::-webkit-scrollbar-track{
    background: #f1f1f1 !important
}
.TableContainer::-webkit-scrollbar-thumb{
    background: #edca78 !important;
}
.TableContainer::-webkit-scrollbar-thumb:hover{
    background: #555 !important;
}