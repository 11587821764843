.Container{
    padding: 10px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    gap:10px
}

.RowContainer{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}

.Icon{
    height: 14px;
    width: 14px;
}

.DateText{
    font-size: 12px;
}