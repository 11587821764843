.TagContainer {
  display: inline-flex;
  height: 2.25rem;
  padding: 0.5rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerM {
  display: inline-flex;
  height: 1.5rem;
  padding: 0.5rem 0.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerS {
  display: inline-flex;
  height: 1.125rem;
  padding: 0.5rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
.TagContainerSM {
  display: inline-flex;
  height: 0.9375rem;
  padding: 0.5rem 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 437.5rem;
}
/*Product Live Status*/
.Live {
  background: #0cc683 !important;
}
.PartiallyLive {
  background: #aae2fa !important;
}
.Checking {
  background: #d8d8d8 !important;
}
.Inactive {
  background: #ff4c05 !important;
}
.Never {
  background: #ff4c05 !important;
}
/*Product Status*/
.Approved {
  background: #058527 !important;
}
.PendingApproval {
  background: #ccffda !important;
}
.Pending {
  background: #d8d8d8 !important;
}
.Restricted {
  background: #F0B59F !important;
}
.Generating {
  background: #e3d9c6 !important;
}
.Waiting {
  background: #e6b540 !important;
}
.Error {
  background: #ea2a33 !important;
}
.Deactivated {
  background: #ea2a33 !important;
}
.NotFound {
  background: #ea2a33 !important;
}
.OnHold {
  background: #FAE0A3 !important;
}
.Fetching {
  background: #898989 !important;
}
.Published {
  background: #B5D78E !important;
}
.StatusText {
  color: #000000e0 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;

}
.StatusTextM {
  color: #000000e0 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;
}
.StatusTextS {
  color: #000000e0 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.005rem !important;

}
.StatusTextSM {
  color: #000000e0 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important;

}
.InfoIconLight {
  color: #ffffff;
}
.InfoIconDark {
  color: #000000e0;
}
.LightStatusColor {
  color: #ffffff !important;
}
.SmallIcon{
  padding: 0.15rem 0;
}