.InfoIcon {
  color: #e6b540 !important;
}
.TooltipHeading {
  color: #828282 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.TooltipBody {
  color: #333 !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.Container {
  background-color: white;
  border-radius: 0.875rem;
}
.Section {
  padding: 3.12rem 2.5rem;
}
.KeywordsWeight{
  color: #2E2E2E !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
}