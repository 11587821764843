.competitors-container {
  //min-width: 90rem;
  min-height: 56.25rem;

  .comps-h2 {
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
    line-height: 44px;
    color: #000000;
    //margin-bottom: 1rem;
    text-align: center;
    padding: 30px;
  }

  .comps-text {
    line-height: 25px;
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.035px;
    line-height: 11px;
    text-align: center;
    color: #7e7e7e;
    margin-bottom: 1.2rem;
  }

  .comp-all-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */


    /* Warning/Main */
    width: 90%;
    color: #B94B20;
  }

  .comp-see {
    width: 10%;
    cursor: pointer;
    display: flex;
    font-weight: bolder;
    text-decoration: underline;
    justify-content: flex-end;
  }

  .comp-button {
    background-color: #E6B540;
    color: white;
    margin: 0.5rem;
    width: 7rem;
    width: 25rem;
    padding: 1.15rem;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 0.7rem;

    &:hover {
      background-color: #E6B540;
    }

    &.Mui-disabled {
      color: white;
      background-color: rgba(222, 200, 148, 0.879);
      cursor: wait;
      pointer-events: all;

      &:hover {
        background-color: rgba(222, 200, 148, 0.879);
      }
    }
  }
}

.next-button-comp {
  width: 250px !important;
  padding: 10px 20px !important;

}

.next-button-comp-fetching {
  width: 300px !important;
  padding: 10px 20px !important;
}

.comp-button-mb {
  width: 100% !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

.fwBold600 {
  font-weight: 600 !important;
}