.Text {
  color: #71747d !important;
}

.Container {
  margin: 4px !important;
  position: relative;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.IsSelected {
  margin: 10px 0px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px;
}

@media (min-width: 801px) and (max-width: 1520px) {
  .IsSelected {
    /*height: 130px;*/
  }
}

.Gap1{
  gap: 1rem !important;
}

.SearchVolume {
  color: #353636 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.028px !important;
}

.DragIcon {
  color: #71747d !important;
}

.Label {
  max-width: 23ch !important;
  height: auto !important;
  overflow: hidden !important;
  color: #71747d !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  cursor: pointer !important;
  padding-bottom: 3px;
  width: 75%;
  border-bottom: 1px solid #71747D !important;

  /* Multi-line ellipsis */
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit text to 2 lines */
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: normal !important; /* Allow line breaks */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.CaptionLabel {
  font-size: 10px !important;
  color: #71747d !important;
  font-weight: 500 !important;
  padding-top: 5px !important;
}

.Caption {
  font-size: 10px !important;
  font-weight: 500 !important;
}
.CurrentRank {
  background-color: #E6B540;
  border: 2px solid #e6b540;
  height: 1.9rem;
  padding: 0.22rem 0.44rem;
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}
.CurrentRank:hover {
  transform: scale(1.3);
}
.Rank {
  color: #ffffff !important;
  text-align: center !important;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100.5% !important; /* 0.62813rem */
}
.RankValue {
  color: #ffffff !important;
  text-align: center !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100.5% !important; /* 0.62813rem */
}
.RankContainer {
  flex-basis: 100%;
}


.Padding8{
  padding-top: 5px;
}