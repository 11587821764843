.CardContainerM{
    padding: 1rem;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 5px 2px 7px 3px #D8D8D8;
}
.IconConatiner{
    background-color: white !important;
}
.IconConatinerD{
    border-radius: 50%;
}
.Description{
    line-height: 18px !important;
}