.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;

  .image-block {
    position: relative;
    flex-basis: calc(33.33% - 1rem);
    text-align: center;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .image-text {
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .prioritise-text{
      transform: translate(25%, -50%);
    }

    .optionalText {
      position: absolute;
      top: 42px;
      right: 66px;
      font-weight: 700;
      color: #e6b540;
    }
  }
}
