.Container {
    width: 630px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.EnableDisableText {
    color: #80828A;
    font-size: 16px;
    font-weight: 400;
}