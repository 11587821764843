.TextContainer{
    width: fit-content;
    flex-grow: 1;
}
.Text {
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Container{
    display: flex;
    max-width: 100vw;
    min-width: 100vw;
    /* border-radius: 8px; */
    box-sizing: border-box;
    background: #333333;
    align-items: center;
    padding: 10px 24px 10px 64px;
    /* margin-bottom: 20px; */
    justify-content: space-between;
}

.ContainerMobile{
    display: flex;
    max-width: 100vw !important;
    flex-direction: row;
    gap: 8px;
    /* border-radius: 8px; */
    background: #333333;
    padding: 8px 12px;
    /* margin-bottom: 20px; */
}

.Button{
    width: 150px;
}

.MButton{
    font-size: 12px !important;
    max-height: 38px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
}