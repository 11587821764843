.InputContainer{
    padding-right: 24px;
    padding-left: 24px;
    margin-top: 14px;
}

.InputContainerM{
    padding-left: 18px;
}

.Container{
    padding-right: 24px;
    padding-left: 24px;
}

.ContainerM{
    padding-right: 12px;
    padding-left: 12px;
    max-width: 100%;
}

.TextInputSubtitle{
    color: #80828A ;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    margin-top: 10px;
}

.ActiveInactive{
    color: #80828A ;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
}

.SiwtchContainer{
    padding: 0px 24px 16px 24px;
}