.Container {
    background: #fff;
    min-height: 100vh;
    width: 100vw;
}

.ContainerM {
    background: #fff;
    min-height: calc(100vh - 54px);
    width: 100vw;
    padding: 0px 20px !important;
}
 
.BackButtonContainer {
    padding: 16px 71px 0px 0px;
    display: flex;
    justify-content: flex-end;
}
.BackButton {
    color: #80828a !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.CardsContainer {
    padding-top: 16px;
}

.CardsContainerM {
    padding-top: 24px;
}

.Title {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color: #000000 !important;
    padding-bottom: 15px !important;
}

.TitleM {
    font-size: 1.25rem !important;
}

.ToBeginThis {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #80828A !important;
}


.OutlineInputMB {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 48px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}

.OutlineInputD {
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 70px !important;
    border: 1px #D9D9D9 !important;
    padding: 10px;
}
