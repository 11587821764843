.dialogContainer{
    width: 30rem;
}
.name {
    /*white-space: nowrap;*/
    margin-right: 15px !important;
    margin-left: 10px !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500 !important;

}
.label{
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
}
.switch{
    margin-left: auto;
}
.limitsM{
    font-size: 10px !important;
    color: #5A5A5A !important;
}
.limits{
    font-size: 10px !important;
    color: #5A5A5A !important;
}
.divider{
    background: #C4C4C4 !important;
}
.FormInput{
    padding: 0px 0px !important;
}
