.Heading {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
}
.Body {
  color: rgba(0, 0, 0, 0.8);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  overflow: hidden !important;
  white-space: pre-line !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.Limit {
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  color: #737373 !important;
}

.LimitExceed {
  color: #ea2a33 !important;
}

.CopyIcon {
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-right: 0.25rem;
  /* color: #737373 !important; */
  color: #E6B540 !important;
}

.BodyContainer {
  border-radius: 0.625rem;
  background: #fff !important;
  box-shadow: 0 3px 10px 0 #f0f2f5 !important;
  display: flex;
  padding: 1.25rem 1.25rem 1.875rem 1.25rem;
  align-items: center;
  gap: 0.625rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}

.IsNewBodyContainer {
  border-radius: 0.625rem;
  background: transparent !important;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem 1.875rem 1.25rem;
  /*align-items: center;*/
  gap: 16px;
  border-bottom: 1px solid #F2F2F2;
  width: 100%;
}

.BodyContainerLimitExceed {
  border: 1px solid #ea2a33 !important;
}

.Live {}

.InActive {
  background: #ffe5e5 !important;
}

.Highlighted {
  background-color: #058527 !important;
  color: white !important;
  padding: 0px !important;
}

.KeywordDensityHighlighted {
  background-color: #fff !important;
  color: #0CC683 !important;
  padding: 0px !important;
}

.KeywordDensityHighlightedRed {
  background-color: #fff !important;
  color: #F93939 !important;
  padding: 0px !important;
}

.CopyLengthContainer {
  height: 20px !important;
}

.EditableInput {
  resize: none !important;
  width: 100%;
  border: none;
  overflow: hidden;
  min-height: 30px;
  font-family: Poppins;
  font-size: 14px;
  line-height: normal !important;
}

.EditableInput:focus {
  outline: none !important;
}

.ShowEditsIcon {
  color: #E6B540 !important;
}

.HeaderButtonContainer {
  height: 40px;
}

.EditBorder {
  border: 1px solid #E6B540
}
/* :changes */
.TransparentBorder {
  border: 1px solid transparent;
}

.PaddingOnePx {
  padding: 1px !important;
}

.PaddingZeroPx {
  padding: 0px;
}

.Blurred {
  color: transparent !important;
  word-break: break-all;
  text-shadow: 0 0 8px #6f6962;
  pointer-events: none;
  user-select: none;
}

.ShowWithoutSpaces {
  flex-wrap: wrap;
  flex-basis: 100% !important;
}