.Input{

}


.Container{
    padding: 0px 32px;
    margin-top: 16px;
}
.ContainerM{
    padding: 0px 17px;
    margin-top: 0px;
}

.AutoComplete{
    background: #ffffff;
    min-height: 50px;
    border-radius: 6px;
    padding-top: 20px;
}

.Subtitle{
    color: #80828A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Title{
    color: #2E2E2E;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}