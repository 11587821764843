.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.Text{
    color: #2E2E2E;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.04px;
    margin-top: 16px;
    margin-bottom: 10px;
    text-align: center !important;
}

.Subtitle{
    color: #80828A;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 50px;
}