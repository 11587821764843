.Text{
    color: #2E2E2E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Subtitle{
    color: #80828A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}