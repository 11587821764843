.Title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
}

.TitleM {
    font-size: 14px;
    font-weight: 500;

    font-family: "Poppins";
}

.SubTitle {
    font-size: 14px;
    color: #80828A;
    font-family: "Poppins";
}

.SubTitleM {
    font-size: 14px;
    color: #80828A;
    font-family: "Poppins";
}

.Label {
    color: #80828A;
    font-size: 12px;
    font-weight: 400
}