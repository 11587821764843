.saveProductsM {
  height: auto;
}

.savedProductsAddButtonM {
  padding: 10px 20px !important;
  border-radius: 5px !important;
}
.SelectionOptions {
  color: #e6b540 !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;

}
.BottomNavigationOptions{
  /*width: 33%;*/
  text-align: center;
}
.BottomNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 90px !important;
}
.ToolbarIcon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  color: #e6b540 !important;
}
