.TooltipCard {
    max-width: 270px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
}

.TooltipProductImage {
    object-fit: contain;
    width: 60px;
    height: 60px;
    border: 2px solid rgba(222, 226, 231, 1);
    border-radius: 8px;
    background-color: #fff;
    padding: 2px;
}

.TooltipProductAsin {
    color: rgba(46, 46, 46, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
}

.TooltipProductTitle {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    display: inline-block;
    margin-right: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.TooltipProductBy {
    color: rgba(137, 137, 137, 1);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.TooltipProductBrand {
    color: rgba(90, 90, 90, 1);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-left: 2px;
}


.TooltipTotalRatings {
    padding-left: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    color: rgba(137, 137, 137, 1);
}

.TooltipRecordsDiv {
    padding: 18px 12px;
    margin-top: 1rem;
    border-radius: 4px;
}

.TooltipCardLeftText {
    color: white !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 16px !important;
}

.TooltipCardRightText {
    padding-left: 4px;
    color: white;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.08px;
}