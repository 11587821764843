.MobileContainer{
    padding-bottom: 80px;
}

.LimitText{
    color: #80828A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}