.Container {
    padding: 2rem;
}

.CellLinesLimitContainer {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space: normal !important;
    /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.Name {
    color: #e6b540 !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.CellText {
    color: #000000DE; 
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}