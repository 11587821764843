.CompetitorContainer {
  flex-shrink: 0;
  border-radius: 0.625rem !important;
  background: #fff !important;
  box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25) !important;
}

.Divider {
  margin-left: 1.12rem !important;
  margin-right: 1.12rem !important;
  border-width: 1px !important;
}

.CompetitorCardMainM {
  border-radius: 14px;
  border: 1px solid #e6b540;
  background: #fff;
  margin: 1rem;
}
