.CaptionText {
  color: #7e7e7e !important;
}

.CaptionTextMobile {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #000;
}

.Container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*padding: 16px;*/
  /*overflow-x: scroll;*/
  max-width: 95vw;
}

.PR50 {
  padding-right: 100px;
}

.MobileContainer{
  padding-left: 10px;
  padding-right: 0;
}

.Caption{
  color: #7E7E7E !important;
  text-align: center;
  text-decoration: underline;
}

.CaptionActive{
  color: black;
  text-align: center;
  text-decoration: underline;
}

.SelectTextContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7E7E7E !important;
  cursor: pointer;
}

.SelectTextContainerActive{
  display: flex;
  align-items: center;
  justify-content: center;
  color: black !important;
  cursor: pointer;
}


.DoAndDontText{
  color: #71747D;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.DoText{
  color: #0CC683;
  margin-left: 6px;
}

.DontText{
  color: #F00;
  margin-left: 6px;
}

.SearchBarDesktop {
  width: 290px !important;
}

.SearchBarMobile {
  width: 100% !important;
}
