.MainContainer {
  padding: 1.12rem 1rem 1.06rem 1.31rem;
  position: relative;
  margin-bottom: 2rem;
}
.CloseContainer {
  display: flex;
  justify-content: flex-end;
}
.DetailsContainer {
}
.CloseIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 1.5rem;
}
.ImageContainer {
  padding: 2.44rem 3.44rem 1.75rem;
  border: 1px solid #dee2e7;
  border-radius: 0.375rem;
  margin-bottom: 0.94rem;
  position: relative;
}
.Coupon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.375rem 0 0 0 !important;
  background: #fff3d8 !important;
  border: 1px solid #e6b540 !important;
  height: 1.875rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Discount {
  color: #2e2e2e !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.MainImage {
  width: 14.25rem;
  height: 7.5625rem;
  object-fit: contain;
}
.OtherImageContainer {
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 0.51rem;
  padding: 0.88rem 0.31rem 0.93rem;
  border: 1px solid #dee2e7;
  border-radius: 0.375rem;
}
.CurrentImage {
  border: 1px solid #e6b540 !important;
}
.OtherImage {
  width: 2.5rem;
  height: 1.32269rem;
  object-fit: contain;
}
.LastImageContainer {
  position: relative;
  background: rgba(0, 0, 0, 0.68) !important;
}
.LastImage {
  opacity: 0.1;
}
.ImageCount {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  leading-trim: both;
  text-edge: cap !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: bolder !important;
  line-height: normal !important;
}
.ProductTitle {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.002rem !important;
}
.RatingText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin: 0 0.62rem !important;
}
.ReviewsText {
  color: #898989 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: underline !important;
}
.PriceM {
  color: #2e2e2e !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0025rem !important;
}
.ActualPrice {
  color: #f00 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  margin-right: 0.31rem !important;
}
.Divider {
  margin: 0.94rem 0 1.25rem !important;
  border-bottom: 0.0625rem solid #d9dbe3 !important;
}

.Title {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important; /* 150% */
  letter-spacing: -0.00175rem !important;
  margin-bottom: 0.94rem !important;
}
.Body {
  color: #71747d !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  white-space: pre-line !important;
}
.Bullet {
  color: #71747d !important;
}
.OverallRating {
  border-radius: 0.375rem;
  border: 1px solid #f0f2f5;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  padding: 0.94rem 0 0.75rem;
}
.OverallText {
  color: #000 !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}
.OverallRatingText {
  color: #000 !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.875rem !important; /* 115% */
  letter-spacing: -0.003rem !important;
}
.ReviewContainer {
  border-radius: 0.375rem;
  border: 1px solid #f0f2f5;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  padding: 1.25rem 0.94rem 1.56rem;
  margin-bottom: 0.62rem !important;
}
.ReviewDate {
  color: #71747d !important;
  text-align: right !important;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}
.ReviewerImage {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 2.5rem;
  margin-right: 0.94rem;
}
.ReviewerName {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.ReviewBody {
  color: #71747d !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  /*margin-left: 4.375rem !important;*/
  white-space: pre-line;
}
.ShowMoreReview {
  color: #e6b540 !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important; /* 150% */
  letter-spacing: -0.002rem !important;
}
