.MenuContainer {
    padding: 25px 20px;
}

.TitleVariation {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #2e2e2e !important;
    width: fit-content !important;
}


.MenuItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 10px 20px 15px !important;
    border-radius: 10px !important;
    gap: 8px;
    box-shadow: 2px 2px 10px 0px #0000001A;
    /* background: #ffffff !important; */
}

.Header {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: #000000 !important;
}

.Title {
    max-width: 560px !important;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;

    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: #2e2e2e !important;
}

.Active {
    border: 1px solid #E6B540 !important;
    background-color: #FDEFCF !important;
    border-radius: 10px !important;
}

.ActiveMenuItem {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 10px 20px 15px !important;
    border-radius: 10px !important;
    gap: 8px;
    box-shadow: 2px 2px 10px 0px #0000001A;
    border: 1px solid #E6B540 !important;
    background-color: #fff3d8 !important;
}

.HoverEffect {
    background-color: white !important;
    border: 1px solid white !important;
}

.HoverEffect:hover {
    background-color: white;
    border: 1px solid #E6B540 !important;
}

.Description {
    color: #80828a !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin: 0.0rem 0 0.5rem 0 !important;
}