.Container {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #D9D9D9;
    display: inline-block;
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    color: #80828A;
    cursor: pointer;
}

.ContainerActive {
    color: #2E2E2E !important;
    border: 1px solid #E6B540;
    background-color: #FFF9EE !important;
}