.Container {
    padding: 2rem;
}

.Title {
    color: #2E2E2E !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    letter-spacing: -0.028px !important;
}


.Limit {
    font-size: 0.75rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    color: #737373 !important;
}