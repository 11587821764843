.BadgeContainer {
    font-size: 10px;
    line-height: 16px;
    font-family: Poppins;
    font-weight: 400;
    padding: 4px 8px;
    width: fit-content;
    /* height: 40px */
    border-radius: 20px; /* Fully round the badge */

    width: fit-content; /* Fit the content's width */
    display: inline-block; /* Ensure proper inline display */
    text-align: center; /* Center-align the text */
}

.Performance {
    background-color: #0CC683;
    color: #ffffff;
}

.Inventory {
    background-color: #AAE2FA;
    color: #2e2e2e;
}

.Pricing {
    background-color: #d8d8d8;
    color: #2e2e2e;
}

.CompetitorPrice{
    background-color: #E3D9C6;
    color: #2e2e2e;
}

.Competitor {
    background-color: #E3D9C6;
    color: #2e2e2e;
}

.Listings {
    background-color: #CCFFDA;
    color: #2e2e2e;
}

.Feedback {
    background-color: #FAE0A3;
    color: #2e2e2e;
}

.Badging {
    background-color: #058527;
    color: #ffffff;
}

.Amazonschoice {
    background-color: #058527;
    color: #ffffff;
}

.Buybox {
    background-color: #2E2E2E;
    color: #ffffff;
}

.Availability {
    background-color: #ebb475;
    color: #ffffff;
}

.Variation {
    background-color: #5bb1f4;
    color: #ffffff;
}

.Fulfillment {
    background-color: #5bf4d3;
    color: #2e2e2e;
}
