.KeywordsCardM {
  border-radius: 14px;
  border: 1px solid #e6b540;
  background: #fff;
  margin: 1rem;
}

.KeywordCardTitleM {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.028px !important;
  border-bottom: 1px solid #000 !important;
}
.TitlePhraseCell {
  color: #5e5a9f !important;
}

.KeywordsRecordTitleM {
  color: #000 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.KeywordsRecordTextM {
  color: #80828a !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100.5% !important;
  padding-top: 6px !important;
}

.KeywordsRecordSubTextM {
  color: #80828a !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.BorderBottom {
  border-bottom: 1px solid #000 !important;
}

.BorderCell {
  border: 2px solid #058527;
  padding: 4px;
}
